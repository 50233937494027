import React from 'react';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';

const MainFooter = () => {
    const { deleteUserFromDatabase } = useDatabase();

    return (
        <div>
            <footer style={{ fontFamily: 'Nunito Sans', fontSize: '12px' }}>
                <a className="text-muted" onClick={(e) => { deleteUserFromDatabase() }}>Delete My Account</a>
                <span className="text-muted"> · </span>
                <a className="text-muted" href="/terms-and-conditions">Terms and Conditions</a>
                <span className="text-muted"> · </span>
                <a className="text-muted" href="/privacy-policy">Privacy Policy</a>
                <span className="text-muted"> · </span>
                <span className="text-muted">Intract © 2021</span>
            </footer>
        </div>
    );
}

export default MainFooter;
