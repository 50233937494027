import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../contexts/WA_FSAuthenticationContext";
import { auth } from "../firebase/firebase.config";

export default function WA_AuthPrivateRoute({ component: Component, redirectTo, needsAuthentication, goToDefaultBoard, ...rest }) {
    const { currentUser } = useAuth()

    if(goToDefaultBoard) {
        if (auth && auth.currentUser) {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Redirect to="/board/rVPrCMHHyeL3J2o68AdT"/>
                    }}
                ></Route>)
        } else {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Redirect to="/welcome" />
                    }}
                ></Route>)
        }
    }

    if (needsAuthentication) {
        if (auth && auth.currentUser) {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Component {...props} />
                    }}
                ></Route>)
        } else {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Redirect to="/welcome" />
                    }}
                ></Route>)
        }
    }

    if (!needsAuthentication) {
        if(auth && !auth.currentUser) {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Component {...props} />
                    }}
                ></Route>)
        } else {
            return (
                <Route
                    {...rest}
                    render={props => {
                        return <Redirect to={"/board/" + auth.currentUser.uid} />
                    }}
                ></Route>)
        }
    }
}