import { React } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from './App';
import { WA_FSAuthenticationProvider } from './contexts/WA_FSAuthenticationContext';
import { WA_FSDatabaseProvider } from './contexts/WA_FSDatabaseContext';
import Login from "./Login";
import PrivacyPolicy from './PrivacyPolicy';
import WA_AuthPrivateRoute from './routes/WA_AuthPrivateRoute';
import TermsAndConditions from './TermsAndConditions';

function Root() {
  return (
    <div style={{ height: '100%' }}>
      <BrowserRouter>
        <WA_FSAuthenticationProvider>
          <WA_FSDatabaseProvider>
            <Switch>
              <WA_AuthPrivateRoute goToDefaultBoard={true} needsAuthentication={true} exact path="/" component={App} />
              <WA_AuthPrivateRoute goToDefaultBoard={true} needsAuthentication={true} exact path="/board" component={App} />
              <WA_AuthPrivateRoute needsAuthentication={true} path="/board/:BOARD_ID" component={App} />
              <WA_AuthPrivateRoute needsAuthentication={false} path="/welcome" exact component={Login} />
              <Route needsAuthentication={false} component={PrivacyPolicy} exact path="/privacy-policy" />
              <Route needsAuthentication={false} component={TermsAndConditions} exact path="/terms-and-conditions" />
            </Switch>
          </WA_FSDatabaseProvider>
        </WA_FSAuthenticationProvider>
      </BrowserRouter>
    </div>
  );
}

export default Root;
