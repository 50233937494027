import React, { useContext, useState, useEffect } from "react";
import { auth } from '../firebase/firebase.config';

const WA_FSAuthenticationContext = React.createContext()

export function useAuth() {
    return useContext(WA_FSAuthenticationContext)
}

export function WA_FSAuthenticationProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(auth.currentUser)
    const [FBToken, setFBToken] = useState();

    const [loading, setLoading] = useState(true)

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login(email, password, setLoginError) {
        auth.signInWithEmailAndPassword(email, password).then((user) => {
            setCurrentUser(user);
            window.location.href = "/board/" + user.user.uid + "?onboarding=true";
        }).catch((error) => {
            setLoginError(error.message);
        })
    }

    function sendEmailVerification() {
        return auth.currentUser.sendEmailVerification()
    }

    function deleteUser() {
        return auth.currentUser.delete()
    }

    function logout() {
        return auth.signOut()
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email) {
        return currentUser.updateEmail(email)
    }

    function updatePassword(password) {
        return currentUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            setLoading(false);

            if (user) {
                user.getIdToken().then((token) => {
                    setFBToken(token);
                })
            }
        })

        return unsubscribe
    }, [])

    const value = {
        FBToken,
        currentUser,
        sendEmailVerification,
        deleteUser,
        login,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }

    return (
        <WA_FSAuthenticationContext.Provider value={value}>
            {!loading && children}
        </WA_FSAuthenticationContext.Provider>
    )
}