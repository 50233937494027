import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import BoardCardDummy_ITEM from './BoardCardDummy_ITEM';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';
import { useAuth } from '../../contexts/WA_FSAuthenticationContext';

const BoardCardDummy = ({ BOARD_ID }) => {

    const { currentUser } = useAuth();
    const { fetchUserFromDatabase, fetchBoardFromDatabase, fetchEntriesFromDatabase } = useDatabase();

    const [currentBatch, setCurrentBatch] = useState(0);
    const [receivedLastBatch, setReceivedLastBatch] = useState(false);

    const [USER, setUSER] = useState();
    const [BOARD, setBOARD] = useState({ title: '🔥 Intract' });
    const [ENTRIES, setENTRIES] = useState([
        ["0", { content: 'Intract is a platform for academics to share anything and everything.' }],
        ["1", { content: 'You can write about any and all things here as long as you\'re kind and keep in mind that there is someone at the other end of the wire with opinions and views as valid as yours.' }],
        ["2", { content: 'Upon signing up you\'ll get a board for you and you alone @ your username. Others can read this board but only you can write on it. This board was intended as a way to share things about yourself, but you do you 🤘' }],
        ["3", { content: 'Finally, keep in mind that you are the very first users of Intract so there may be bugs and faults while I fix the site up, I hope this doesn\'t detract from your experience on the platform.' }],
    ]);

    return (
        <div>
            <Container>
                <Row>
                    <Col md={{ span: 9 }}>
                        <div style={{ display: 'block' }}>
                            <a style={{ fontSize: '12px', color: '#8581C7' }} href="#">@gokhan</a>
                        </div>
                        {BOARD && <h4 style={{ fontWeight: '700' }}>{BOARD.title}</h4>}
                    </Col>
                </Row>
            </Container>
            <br></br>
            <Container>
                <Col>
                    {ENTRIES.map((value, key) => {
                        console.log("value", value);
                        return (<BoardCardDummy_ITEM key={"entry-" + value[0]} ENTRY={value[1]} />)
                    })}
                </Col>
            </Container>
        </div>)
}

export default BoardCardDummy;
