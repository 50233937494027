import Axios from 'axios';
import React, { useRef } from 'react';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';
import { Button, Col, Form, Row, InputGroup, FormControl } from 'react-bootstrap';

const BoardListCard_CREATE_BOARD_ITEM = ({ BOARD }) => {

    const { createBoardOnDatabase } = useDatabase();

    const TITLE_REF = useRef();

    async function createBoard() {
        console.info("createBoard");
        createBoardOnDatabase(TITLE_REF.current.value).then((board) => {
            window.location.href = "/board/" + board.id;
        }).catch((error) => {
            console.error(error);
        })
    }

    return (
        <div>
            <Row style={{ padding: '8px', fontFamily: 'Nunito Sans', fontSize: '16px' }}>
                <Col>
                    <InputGroup className="mb-3">
                        <FormControl ref={TITLE_REF} placeholder="Rejected Harry Potter spells" />
                        <InputGroup.Append>
                            <Button onClick={(e) => createBoard()} style={{ color: 'white', border: 'none', backgroundColor: '#8581C7', fontWeight: 'bold', fontSize: '12px' }}>Submit</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
        </div>
    );
}

export default BoardListCard_CREATE_BOARD_ITEM;
