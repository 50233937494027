import moment from 'moment';
import { React, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import VoteGroup from '../VoteGroup/VoteGroup';

const BoardCard_ITEM = ({ ENTRY }) => {

    const [USER_VOTE, setUSER_VOTE] = useState(0);
    const [VOTE_SUM, setVOTE_SUM] = useState();
    const [VOTES, setVOTES] = useState([]);

    useEffect(() => {
        setVOTE_SUM(0);
    }, [ENTRY]);

    return (
        <div>
            <Row style={{ padding: '8px', fontFamily: 'Nunito Sans' }}>
                <Col style={{ pointerEvents: 'none' }} xs={{ span: 2 }} sm={{ span: 1 }}>
                    <VoteGroup ENTRY={ENTRY} VOTE_SUM={VOTE_SUM} setVOTE_SUM={setVOTE_SUM} USER_VOTE={USER_VOTE} setUSER_VOTE={setUSER_VOTE} />
                </Col>
                <Col xs={{ span: 10 }} sm={{ span: 11 }}>
                    <div style={{ display: 'block' }}>
                        <a style={{ fontSize: '12px', color: '#8581C7' }} href='#'>@gokhan</a>
                        <span style={{ fontSize: '12px', color: 'lightgray' }} href='#'> • {moment(new Date()).format('MM/DD/YYYY HH:mm')}</span>
                    </div>
                    <span>{ENTRY.content}</span>
                </Col>
            </Row>
        </div>
    );
}

export default BoardCard_ITEM;
