import React from 'react';
import { Container, Col, Row} from 'react-bootstrap';

const BoardListCard_ITEM = ({BOARD, BOARD_ID}) => {
    return (
        <div>
            <Row style={{padding: '8px', fontFamily: 'Nunito Sans', fontSize: '16px'}}>
                <Col xs={{span: 10}}>
                    <a href={"/board/" + BOARD_ID} style={{color: 'black'}}>{BOARD.title}</a>
                </Col>
                {false && <Col xs={{span: 2}} style={{color: 'gray'}}>3</Col>}
            </Row>
        </div>
    );
}

export default BoardListCard_ITEM;
