import Axios from 'axios';
import moment from 'moment';
import React, { useRef } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';
import VoteGroup from '../VoteGroup/VoteGroup';

const BoardCard_CREATE_ENTRY_ITEM = ({ BOARD_ID, BOARD, USER }) => {

    const CONTENT_REF = useRef();

    const { createEntryOnDatabase, user } = useDatabase();

    const createEntry = () => {
        console.info("createEntry");
        createEntryOnDatabase(BOARD_ID, CONTENT_REF.current.value);
    }
    return (
        <div>
            <Row style={{padding: '8px', fontFamily: 'Nunito Sans'}}>
                <Col xs={{span: 2}} sm={{span: 1}}>
                    <VoteGroup disabled ENTRY = {null} VOTE_SUM = {0} setVOTE_SUM = {null} USER_VOTE = {0} setUSER_VOTE = {null} />
                </Col>
                <Col xs={{span: 10}} sm={{span: 11}}>
                    <div style={{display: 'block'}}>
                        <a style={{fontSize: '12px', color: '#8581C7'}}>{"@" + user.username}</a>
                        <span style={{fontSize: '12px', color: 'lightgray'}} href='#'> • {moment().format('MM/DD/YYYY HH:mm')}</span>
                    </div>
                    <Form>
                        <Form.Group>
                            <Form.Control ref={CONTENT_REF} placeholder="I think that..." as="textarea" rows={3} />
                            <br></br>
                            <Button onClick={(e) => createEntry()} style={{border: 'none', color: 'white', backgroundColor: '#8581C7', fontWeight: 'bold', fontSize: '14px', margin: 'auto 0px auto auto', display: 'block'}}>Submit</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default BoardCard_CREATE_ENTRY_ITEM;
