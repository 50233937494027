import { Col, Container, Row } from 'react-bootstrap';
import './App.css';
import BoardCardDummy from './components/BoardCardDummy/BoardCardDummy';
import BoardListCardLogin from './components/BoardListCardLogin/BoardListCardLogin';
import MainNavbar from './components/MainNavbar/MainNavbar';

function Login() {
  return (
    <div className="App">
      <Container style={{ height: '100vh' }}>
        <div style={{ minHeight: 'calc(100vh - 24px)' }}>
          <MainNavbar></MainNavbar>
          <br></br>
          <Row>
            <Col md={{ span: 8 }} style={{ backgroundColor: 'white' }}>
              <BoardCardDummy style={{ maxHeight: '100%' }}></BoardCardDummy>
            </Col>
            <Col md={{ span: 4 }} style={{ backgroundColor: 'white', maxHeight: '100%' }}>
              <BoardListCardLogin style={{ maxHeight: '100%' }}></BoardListCardLogin>
            </Col>
          </Row>
        </div>
        <div style={{ height: '24px' }}>
          <div>
            <footer style={{ fontFamily: 'Nunito Sans', fontSize: '12px' }}>
              <a className="text-muted" href="/terms-and-conditions">Terms and Conditions</a>
              <span className="text-muted"> · </span>
              <a className="text-muted" href="/privacy-policy">Privacy Policy</a>
              <span className="text-muted"> · </span>
              <span className="text-muted">Intract © 2021</span>
            </footer>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Login;
