import './App.css';
import { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BoardListCard from './components/BoardListCard/BoardListCard';
import { Container, Row, Col, Modal, Image, InputGroup, FormControl, Button } from 'react-bootstrap'
import BoardCard from './components/BoardCard/BoardCard';
import MainNavbar from './components/MainNavbar/MainNavbar';
import MainFooter from './components/MainFooter/MainFooter';
import { useDatabase } from './contexts/WA_FSDatabaseContext';
import { useAuth } from './contexts/WA_FSAuthenticationContext';

function App({ match }) {

  const { currentUser, sendEmailVerification, logout } = useAuth();
  const query = useQuery();
  const [onboarding_, setOnboarding_] = useState(false);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  useEffect(() => {
    console.log("onboarding", query.get("onboarding"));

    setOnboarding_(query.get("onboarding") == "true");
  }, []);



  const { createBoardOnDatabase } = useDatabase();

  const TITLE_REF = useRef();

  async function createBoard() {
    console.info("createBoard");
    createBoardOnDatabase(TITLE_REF.current.value).then((board) => {
      window.location.href = "/board/" + board.id;
    }).catch((error) => {
      console.error(error);
    })
  }

  return (
    (currentUser && currentUser.emailVerified) ?
      <div className="App">
        <Container>
          <div style={{ minHeight: 'calc(100vh - 24px)' }}>
            <MainNavbar></MainNavbar>
            <br></br>
            <Row>
              <Col md={{ span: 8 }} style={{ backgroundColor: 'white' }}>
                <BoardCard BOARD_ID={match.params.BOARD_ID} style={{ maxHeight: '100%' }}></BoardCard>
              </Col>
              <Col md={{ span: 4 }} style={{ backgroundColor: 'white', maxHeight: '100%' }}>
                <BoardListCard style={{ maxHeight: '100%' }}></BoardListCard>
              </Col>
            </Row>
          </div>
          <div style={{ height: '24px' }}>
            <MainFooter />
          </div>
        </Container>

        <Modal show={onboarding_} onHide={() => { setOnboarding_(false); }}>
          <Modal.Header closeButton>
            <Image src="https://user-images.githubusercontent.com/25232178/104131306-9bd3bc80-5386-11eb-8779-058546fd7f52.png" style={{ height: '2.2em', margin: 'auto 4px', display: 'block' }} />
            <Modal.Title style={{ display: 'block' }}>Welcome to Intract!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <p>Intract is a new place for academics all around the world to discuss ideas on any and all things.</p>

              <ul>
                <li>📚 You read your first Murakami book and you just have to tell others? Create the board <b><a style={{ color: '#8581C7' }} href="/board/EXqw6gg0B0okmm0KGzEz">Kafka on the Shore (📚 by Haruki Murakami)</a></b> and go off.</li>
                <li>🐧 You saw a cool news article about penguins? Create the board <b><a style={{ color: '#8581C7' }} href="/board/kmelbwp3vntRDWhzwZQ7">Penguins (the fun stuff)</a></b> and let us know.</li>
                <li>🤔 Are you trying to decide if a PhD is really for you or not? Just ask it on <b><a style={{ color: '#8581C7' }} href="/board/T3KT5PNUi7RvxkYMIUNh">Things to do to know if you really want to do a PhD (advice)</a></b>.</li>
              </ul>

              <p>And when you write something, just <b>Be nice, keep warm.</b> Don't let people on the other side feel bad about their questions or comments and definitely don't personally attack them.</p>

              <p>Go on now, create your first board and join the conversation:</p>

              <InputGroup className="mb-3">
                <FormControl ref={TITLE_REF} placeholder="Start a convo (eg. Rejected Harry Potter spells)" />
                <InputGroup.Append>
                  <Button onClick={(e) => createBoard()} style={{ color: 'white', border: 'none', backgroundColor: '#8581C7', fontWeight: 'bold', fontSize: '12px' }}>Submit</Button>
                </InputGroup.Append>
              </InputGroup>

              <p>Happy camping!</p>
            </Container>
          </Modal.Body>
        </Modal>
      </div> : <><Modal show={true}>
        <Modal.Header closeButton>
          <Modal.Title style={{ display: 'block' }}>Please verify your account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <p>We have sent you a verification e-mail, please click <a href="/welcome" onClick={() => { logout() }}>here</a> once you verify your account to sign back in. Click <a href="#" onClick={() => { sendEmailVerification(); }}>here</a> to resend verification e-mail and click <a href="#" onClick={() => { logout(); }}>here</a> to logout.</p>
          </Container>
        </Modal.Body>
      </Modal></>
  )
}

export default App;
