import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import BoardCard_CREATE_ENTRY_ITEM from './BoardCard_CREATE_ENTRY_ITEM';
import BoardCard_ITEM from './BoardCard_ITEM';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';
import { useAuth } from '../../contexts/WA_FSAuthenticationContext';

const BoardCard = ({ BOARD_ID }) => {

    const { currentUser } = useAuth();
    const { fetchUserFromDatabase, fetchBoardFromDatabase, fetchEntriesFromDatabase } = useDatabase();

    const [currentBatch, setCurrentBatch] = useState(0);
    const [receivedLastBatch, setReceivedLastBatch] = useState(false);

    const [USER, setUSER] = useState();
    const [BOARD, setBOARD] = useState();
    const [ENTRIES, setENTRIES] = useState([]);

    const [ENTRY_FILTER, setENTRY_FILTER] = useState('NEW');

    const [SHOW_CREATE, setSHOW_CREATE] = useState(false);

    useEffect(() => {
        if (BOARD_ID) {
            fetchBoardFromDatabase(BOARD_ID).then((board) => {
                setBOARD(board);
                fetchUserFromDatabase(board.user.path.split("/")[1]).then((user) => {
                    setUSER(user);
                }).catch((err) => {
                    console.error(err);
                })
                console.info("board", board);
            }).catch((err) => {
                console.error(err);
            })

            fetchNextBatchOfEntriesFromDatabase();
        }
    }, [BOARD_ID]);

    const fetchNextBatchOfEntriesFromDatabase = () => {
        fetchEntriesFromDatabase(BOARD_ID, ENTRIES[ENTRIES.length - 1] ? ENTRIES[ENTRIES.length - 1] : null).then((entries) => {
            setENTRIES([...ENTRIES, ...entries]);
            setCurrentBatch(currentBatch + 1);
            if (entries.length < 20) {
                setReceivedLastBatch(true);
            }
            console.info("entries", [...ENTRIES, entries]);
        }).catch((err) => {
            console.error(err);
        })
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col md={{ span: 9 }}>
                        <div style={{ display: 'block' }}>
                            {USER && <a style={{ fontSize: '12px', color: '#8581C7' }} href={'/board/' + USER[1].board.path.split("/")[1]}>{"@" + USER[1].username}</a>}
                        </div>
                        {BOARD && <h4 style={{ fontWeight: '700' }}>{BOARD.title}</h4>}
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    {false && <Col xs={{ span: 9 }} md={{ span: 3 }}>
                        <ButtonGroup style={{ width: '100%' }} aria-label="Basic example">
                            <Button className={(ENTRY_FILTER === 'HOT') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setENTRY_FILTER("HOT") }}>🔥</Button>
                            <Button className={(ENTRY_FILTER === 'NEW') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setENTRY_FILTER("NEW") }}>👶</Button>
                            <Button className={(ENTRY_FILTER === 'TOP') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setENTRY_FILTER("TOP") }}>🏅</Button>
                        </ButtonGroup>
                    </Col>}
                    {BOARD && !BOARD.isUserBoard &&
                        <>
                            <Col xs={{ span: 3, offset: 9 }}>
                                <Button onClick={(e) => { setSHOW_CREATE(!SHOW_CREATE) }} style={{ border: 'none', color: '#8581C7', backgroundColor: 'white', fontWeight: 'bold', fontSize: '14px', display: 'block', margin: 'auto 0px auto auto' }} >Add Entry</Button>
                            </Col>
                        </>
                    }
                    {BOARD && (BOARD.isUserBoard && (BOARD.user.path.split("/")[1] == currentUser.uid)) &&
                        <>
                            <Col xs={{ span: 9 }}>
                                <div style={{ display: 'block' }}>
                                    <span style={{ fontSize: '12px', color: '#8581C7' }}>
                                        {"This is for you to share things about yourself. Others can't write here."}
                                    </span>
                                </div>
                            </Col>
                            <Col xs={{ span: 3 }}>
                                <Button onClick={(e) => { setSHOW_CREATE(!SHOW_CREATE) }} style={{ border: 'none', color: '#8581C7', backgroundColor: 'white', fontWeight: 'bold', fontSize: '14px', display: 'block', margin: 'auto 0px auto auto' }} >Add Entry</Button>
                            </Col>
                        </>
                    }
                </Row>
            </Container>
            <br></br>
            <Container>
                <Col>
                    {SHOW_CREATE && <BoardCard_CREATE_ENTRY_ITEM BOARD_ID={BOARD_ID} BOARD={BOARD} USER={USER} />}
                    {ENTRIES.map((value, key) => {
                        console.log("value", value);
                        return (<BoardCard_ITEM key={"entry-" + value.id} ENTRY={value} />)
                    })}
                    {!receivedLastBatch && <Button style={{ border: 'none', color: '#8581C7', backgroundColor: 'white', fontWeight: 'bold', fontSize: '12px', margin: 'auto 0px auto auto', display: 'block' }}  onClick={(e) => { fetchNextBatchOfEntriesFromDatabase() }}>Load more...</Button>}
                </Col>
            </Container>
        </div>)
}

export default BoardCard;
