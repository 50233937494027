import React, { useContext, useState, useEffect } from "react"
import { db } from '../firebase/firebase.config'
import { useAuth } from './WA_FSAuthenticationContext';
import firebase, { Query } from 'firebase';
import moment from 'react-moment';

const WA_FSDatabaseContext = React.createContext();

export function useDatabase() {
    return useContext(WA_FSDatabaseContext);
}

export function WA_FSDatabaseProvider({ children }) {

    const [user, setUser] = useState();

    const { currentUser, signup, deleteUser, sendEmailVerification } = useAuth();

    async function fetchBoardFromDatabase(board_id) {
        console.log("fetchBoardFromDatabase");
        const snapshot = await db.collection('boards').doc(board_id).get();

        var source = snapshot.metadata.fromCache ? "local cache" : "server";
        console.log("Data came from " + source);

        return snapshot.data();
    }

    async function fetchBoardsFromDatabase(startAtDate) {

        console.log("fetchBoardsFromDatabase");
        var startAtDate_ = null;
        if (!startAtDate) {
            startAtDate_ = firebase.firestore.Timestamp.fromDate(new Date());
        } else {
            startAtDate_ = startAtDate.time;
        }

        var all_cached_ids = [];
        var boards_ = {};

        // read from cache
        var snapshot = await db.collection('boards').where("isUserBoard", "==", false).get({ source: 'cache' });

        snapshot.forEach((doc) => {
            all_cached_ids.push(doc.id);
            if (doc.data().time < startAtDate_) {
                var data_ = doc.data();
                data_["id"] = doc.id;
                boards_[doc.id] = data_;
            }
        });

        console.info("fetchBoardsFromDatabase CACHE", Object.keys(boards_).length);

        // read all new from the server
        var snapshot_ = null;
        if(all_cached_ids && all_cached_ids.length != 0) {
            snapshot_ = await db.collection('boards').where("isUserBoard", "==", false).where(firebase.firestore.FieldPath.documentId(), "not-in", all_cached_ids).get({ source: 'server' });
        } else {
            snapshot_ = await db.collection('boards').where("isUserBoard", "==", false).get({ source: 'server' });
        }

        snapshot_.forEach((doc) => {
            // localStorage.setItem('board/' + doc.id, [doc.id, doc.data()]);
            if (doc.data().time < startAtDate_) {
                var data_ = doc.data();
                data_["id"] = doc.id;
                boards_[doc.id] = data_;
            }
        });

        console.info("fetchBoardsFromDatabase SERVER", snapshot_.docs.length);

        var val_ = Object.values(boards_);
        val_ = val_.sort(compare);

        return val_.slice(0, 10);
    }

    function compare(a, b, field = "time") {
        if (a[field] < b[field]) {
            return 1;
        }
        if (a[field] > b[field]) {
            return -1;
        }
        return 0;
    }

    function createBoardOnDatabase(title) {
        console.log("createBoardOnDatabase");
        if (currentUser) {
            var promise = new Promise(function (resolve, reject) {

                db.collection('boards').add({
                    time: firebase.firestore.Timestamp.fromDate(new Date()),
                    title: title,
                    user: db.doc('users/' + currentUser.uid), // TO DO: Change to current user
                    isUserBoard: false
                }).then((board) => {
                    resolve(board);
                }).catch((err) => {
                    reject(err);
                    console.error(err);
                })
            });

            return promise;
        }
    }

    function createUserBoardOnDatabase(title, user_id) {
        console.log("createBoardOnDatabase");
        db.collection('boards').doc(user_id).set({
            time: firebase.firestore.Timestamp.fromDate(new Date()),
            title: title,
            user: db.doc('users/' + user_id), // TO DO: Change to current user
            isUserBoard: true
        }, { merge: true }).then(() => {
            window.location.href = "/board/" + user_id + "?onboarding=true";
        }).catch((err) => {
            console.error(err);
        })

    }

    async function fetchEntriesFromDatabase(board_id, startAtDate) {
        console.log("fetchEntriesFromDatabase");
        var startAtDate_ = null;
        if (!startAtDate) {
            startAtDate_ = firebase.firestore.Timestamp.fromDate(new Date());
        } else {
            startAtDate_ = startAtDate.time;
        }

        var all_cached_ids = [];
        var entries_ = {};

        // read from cache
        var snapshot = await db.collection('entries').where("board", "==", db.doc('boards/' + board_id)).get({ source: 'cache' });

        snapshot.forEach((doc) => {
            all_cached_ids.push(doc.id);
            if (doc.data().time < startAtDate_) {
                var data_ = doc.data();
                data_["id"] = doc.id;
                entries_[doc.id] = data_;
            }
        });

        console.info("fetchEntriesFromDatabase CACHE", Object.keys(entries_).length);

        // read all new from the server
        var snapshot_ = null;
        if(all_cached_ids && all_cached_ids.length != 0) {
            snapshot_ = await db.collection('entries').where("board", "==", db.doc('boards/' + board_id)).where(firebase.firestore.FieldPath.documentId(), "not-in", all_cached_ids).get({ source: 'server' });
        } else {
            snapshot_ = await db.collection('entries').where("board", "==", db.doc('boards/' + board_id)).get({ source: 'server' });
        }

        snapshot_.forEach((doc) => {
            // localStorage.setItem('board/' + doc.id, [doc.id, doc.data()]);
            if (doc.data().time < startAtDate_) {
                var data_ = doc.data();
                data_["id"] = doc.id;
                entries_[doc.id] = data_;
            }
        });

        console.info("fetchEntriesFromDatabase SERVER", snapshot_.docs.length);

        var val_ = Object.values(entries_);
        val_ = val_.sort(compare);

        return val_.slice(0, 20);
    }

    function createEntryOnDatabase(board_id, entry) {
        console.log("createEntryOnDatabase");
        if (currentUser && user) {
            db.collection('entries').add({
                time: firebase.firestore.Timestamp.fromDate(new Date()),
                board: db.doc('boards/' + board_id),
                content: entry,
                user: db.doc('users/' + currentUser.uid),
                username: user.username
            }).then(() => { }).catch((err) => {
                console.error(err);
            })
        }
    }

    async function fetchUserFromDatabase(user_id) {
        if (currentUser) {
            console.info("fetchUserFromDatabase")
            const snapshot = await db.collection('users').doc(user_id).get();
            var source = snapshot.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
            localStorage.setItem('user/' + snapshot.id, [snapshot.id, snapshot.data()]);
            return [snapshot.id, snapshot.data()];
        }
    }

    async function fetchCurrentUserFromDatabase() {
        if (currentUser) {
            console.info("fetchCurrentUserFromDatabase")

            const snapshot = await db.collection('users').doc(currentUser.uid).get();
            var source = snapshot.metadata.fromCache ? "local cache" : "server";
            console.log("Data came from " + source);
            setUser(snapshot.data());
        }
    }

    function deleteCurrentUserFromDatabase() {
        if (currentUser) {
            db.collection('users').doc(currentUser.uid).delete().then(() => {
                deleteUser().then(() => {
                    console.log("Successfully deleted...")
                }).catch((err) => { console.error(err) })
            }).catch((error) => {
                console.error("Error removing document: ", error);
            });
        }
    }

    function createUser(email, password, data, setSignUpError, setVerification) {
        signup(email, password).then((user) => {
            sendEmailVerification();
            data["board"] = db.doc("boards/" + user.user.uid);
            user.user.updateProfile({ displayName: data["username"] }).then(() => {
                updateUser(user.user.uid, data);
                createUserBoardOnDatabase("@" + data["username"], user.user.uid);
                setVerification(true);
            }).catch((err) => {
                console.error(err);
                setSignUpError(err.message);
            })
        }).catch((error) => {
            console.error(error);
            setSignUpError(error.message);
        })
    }

    function updateUser(userID, data) {
        db.collection('users').doc(userID).set(data, { merge: true });
    }



    useEffect(() => {
        if (currentUser) {
            // console.log("Fetching current user from database...")
            fetchCurrentUserFromDatabase();
        }
    }, [currentUser])

    const value = {
        user,
        fetchBoardFromDatabase,
        fetchBoardsFromDatabase,
        createBoardOnDatabase,
        fetchEntriesFromDatabase,
        createEntryOnDatabase,
        fetchUserFromDatabase,
        fetchCurrentUserFromDatabase,
        deleteCurrentUserFromDatabase,
        createUser,
        updateUser
    }

    return (
        <WA_FSDatabaseContext.Provider value={value}>
            {children}
        </WA_FSDatabaseContext.Provider>
    )
}