import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Col, Container, Row } from 'react-bootstrap';
import BoardListCard_CREATE_BOARD_ITEM from './BoardListCard_CREATE_BOARD_ITEM';
import BoardListCard_ITEM from './BoardListCard_ITEM';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';

import './BoardList.css'

const BoardListCard = () => {

    const { fetchBoardsFromDatabase } = useDatabase();

    const [receivedLastBatch, setReceivedLastBatch] = useState(false);

    const TITLE_REF = useRef();

    const [BOARDS, setBOARDS] = useState([]);

    const [SHOW_CREATE, setSHOW_CREATE] = useState(false);

    const [BOARD_FILTER, setBOARD_FILTER] = useState("HOT");

    useEffect(() => {
        fetchNextBatchOfBoardsFromDatabase();
    }, []);

    const fetchNextBatchOfBoardsFromDatabase = () => {
        fetchBoardsFromDatabase(BOARDS[BOARDS.length - 1] ? BOARDS[BOARDS.length - 1] : null).then((boards) => {
            setBOARDS([...BOARDS, ...boards]);
            if (boards.length < 10) {
                setReceivedLastBatch(true);
            }
            console.info("boards", [...BOARDS, ...boards]);
        }).catch((err) => {
            console.error(err);
        })
    }

    return (
        <div>
            <Container>
                <Row>
                    <Col md={{ span: 6 }}>
                        <h4>Boards</h4>
                    </Col>
                    <Col md={{ span: 6 }}>
                        <Button onClick={(e) => { setSHOW_CREATE(!SHOW_CREATE) }} style={{ border: 'none', color: '#8581C7', backgroundColor: 'white', fontWeight: 'bold', fontSize: '12px', margin: 'auto 0px auto auto', display: 'block' }}>Create Board</Button>
                    </Col>
                </Row>
            </Container>
            {false && <Container>
                <Row>
                    <Col>
                        <ButtonGroup style={{ width: '100%' }} aria-label="Basic example">
                            <Button className={(BOARD_FILTER === 'HOT') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setBOARD_FILTER("HOT") }}>🔥</Button>
                            <Button className={(BOARD_FILTER === 'NEW') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setBOARD_FILTER("NEW") }}>👶</Button>
                            <Button className={(BOARD_FILTER === 'TOP') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none' }} onClick={(e) => { setBOARD_FILTER("TOP") }}>🏅</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>}
            <Container style={{ overflowY: 'scroll' }}>
                {SHOW_CREATE && <BoardListCard_CREATE_BOARD_ITEM />}
                {BOARDS.map((value, key) => {
                    return (<BoardListCard_ITEM key={"board-" + value.id} BOARD={value} BOARD_ID={value.id} />)
                })}
                {!receivedLastBatch && <Button style={{ border: 'none', color: '#8581C7', backgroundColor: 'white', fontWeight: 'bold', fontSize: '12px', margin: 'auto 0px auto auto', display: 'block' }} onClick={(e) => { fetchNextBatchOfBoardsFromDatabase() }}>Load more...</Button>}
            </Container>
        </div>
    );
}

export default BoardListCard;
