import React from 'react';
import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap'
import { useAuth } from '../../contexts/WA_FSAuthenticationContext';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';
import { faSignOutAlt, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MainNavbar = () => {

    const { user } = useDatabase();
    const { currentUser, logout } = useAuth();

    return (
        <div>
            <Navbar sticky bg='bg-white' expand="md" style={{ backgroundColor: 'white' }}>
                <Navbar.Brand href="/board/rVPrCMHHyeL3J2o68AdT" style={{ fontFamily: 'Nunito Sans', fontWeight: '900' }}><img style={{ maxHeight: '2em' }} src="https://user-images.githubusercontent.com/25232178/104131306-9bd3bc80-5386-11eb-8779-058546fd7f52.png"></img><span style={{ verticalAlign: 'middle' }}>Intract</span></Navbar.Brand>
                {false && <Form inline>
                    <FormControl style={{ fontFamily: 'Nunito Sans' }} type="text" placeholder="Search" className="mr-sm-3" />
                </Form>}
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                {currentUser && user && <Navbar.Collapse style={{ border: 'none !important' }} id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Item>
                            <Nav.Link href={"/board/" + currentUser.uid} style={{ fontFamily: 'Nunito Sans', fontWeight: 'bold', margin: 'auto', color: 'black' }}>{"@" + user.username}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item onClick={(e) => { logout() }}>
                            <FontAwesomeIcon style={{ color: 'red', height: '100%' }} icon={faSignOutAlt} />
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>}
            </Navbar>
        </div>
    );
}

export default MainNavbar;
