import Axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, FormControl, ButtonGroup, Form, Row, Alert } from 'react-bootstrap';
import './BoardListLogin.css';
import { useAuth } from '../../contexts/WA_FSAuthenticationContext';
import { useDatabase } from '../../contexts/WA_FSDatabaseContext';

const BoardListCardLogin = () => {

  // auth
  const { createUser } = useDatabase();
  const { login, signup, logout, sendEmailVerification, currentUser } = useAuth();

  const REG_USERNAME_REF = useRef();
  const REG_EMAIL_REF = useRef();
  const REG_PASS_REF = useRef();
  const REG_PASSAGN_REF = useRef();

  const LOG_EMAIL_REF = useRef();
  const LOG_PASS_REF = useRef();

  const [loginError, setLoginError] = useState();
  const [signUpError, setSignUpError] = useState();
  const [verify, setVerify] = useState();

  const [BOARDS, setBOARDS] = useState([]);

  const [SHOW_CREATE, setSHOW_CREATE] = useState(false);

  const [LR_FILTER, setLR_FILTER] = useState("LOGIN");

  const registerToFB = () => {

    const val = {
      email: REG_EMAIL_REF.current.value,
      username: REG_USERNAME_REF.current.value,
      password: REG_PASS_REF.current.value
    }

    if (true) { // (val.email.endsWith(".harvard.edu") || val.email.endsWith("@harvard.edu")) {
      try {
        createUser(val.email, val.password, {
          username: val.username
        }, setSignUpError, setVerify)
      } catch (error) {
        console.error(error)
      }
    } else {
      console.error("Please use your academic e-mail.")
    }
  }

  const loginToFB = () => {

    const val = {
      email: LOG_EMAIL_REF.current.value,
      password: LOG_PASS_REF.current.value
    }

    try {
      login(val.email, val.password, setLoginError);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <ButtonGroup style={{ width: '100%' }} aria-label="Basic example">
              <Button className={(LR_FILTER === 'LOGIN') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none', color: 'black' }} onClick={(e) => { setLR_FILTER("LOGIN") }}>Login</Button>
              <Button disabled className={(LR_FILTER === 'REGISTER') ? 'filter-active' : ''} style={{ border: 'none', borderRadius: '0px', background: 'none', color: 'black' }} onClick={(e) => { setLR_FILTER("REGISTER") }}>Register</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Container>
      <br></br>
      {LR_FILTER === "LOGIN" && (<Container>
        <Row>
          <Col>
            <Form className="mb-3">
              {loginError && <Alert onClose={(e) => setLoginError()} dismissible >
                {loginError}
              </Alert>}
              <Form.Text>Academic E-Mail</Form.Text>
              <FormControl ref={LOG_EMAIL_REF} type="email" placeholder="Enter e-mail" />
              <Form.Text>Password</Form.Text>
              <FormControl ref={LOG_PASS_REF} type="password" placeholder="Enter password" />
              <br></br>
              <Button onClick={(e) => { loginToFB() }} style={{ border: 'none', color: 'white', backgroundColor: '#8581C7', fontWeight: 'bold', fontSize: '14px', margin: 'auto 0px auto auto', display: 'block' }}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>)}
      {LR_FILTER === "REGISTER" && (<Container style={{pointerEvents: 'none'}}>
        <Row>
          <Col>
            <Form className="mb-3">
              {signUpError && <Alert onClose={(e) => setSignUpError()} dismissible >
                {signUpError}
              </Alert>}
              <Form.Text>Username</Form.Text>
              <FormControl ref={REG_USERNAME_REF} type="text" placeholder="Enter username" />
              <Form.Text>Academic E-Mail</Form.Text>
              <FormControl ref={REG_EMAIL_REF} type="e-mail" placeholder="Enter e-mail" />
              <Form.Text>Password</Form.Text>
              <FormControl ref={REG_PASS_REF} type="password" placeholder="Your password" />
              <Form.Text>Password (again)</Form.Text>
              <FormControl ref={REG_PASSAGN_REF} type="password" placeholder="Your password (again)" />
              <br></br>
              <Button onClick={(e) => { alert("You can't register to Intract at this point 😢 We're sorry!"); }} style={{ border: 'none', color: 'white', backgroundColor: '#8581C7', fontWeight: 'bold', fontSize: '14px', margin: 'auto 0px auto auto', display: 'block' }}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>)}
    </div>
  );
}

export default BoardListCardLogin;
