import Axios from 'axios';
import React from 'react';
import { Container, Row, Tooltip, OverlayTrigger, Button } from 'react-bootstrap';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const VoteGroup = ({ disabled, ENTRY, VOTE_SUM, setVOTE_SUM, USER_VOTE, setUSER_VOTE }) => {

    const voteOnEntry = (value) => {
        console.warn("VOTIN")
        Axios.post("http://localhost:3001/api/voteOnEntry", {
            ENTRYID: ENTRY.id,
            USERID: 'asdasdas',
            VALUE: value
        }).then((res) => {
            if (USER_VOTE + value > 0) {
                setUSER_VOTE(1);
                setVOTE_SUM(VOTE_SUM - USER_VOTE + 1)
            } else if (USER_VOTE + value < 0) {
                setUSER_VOTE(-1);
                setVOTE_SUM(VOTE_SUM - USER_VOTE - 1)
            } else {
                setUSER_VOTE(0);
                setVOTE_SUM(VOTE_SUM - USER_VOTE)
            }
        }).catch((err) => {
            console.error(err);
        })
    }

    return (
        disabled ?
            <div style={{ pointerEvents: 'none', opacity: '0.6' }}>
                <Container>
                    <Row><FaChevronUp onClick={(e) => { voteOnEntry(1) }} style={(USER_VOTE == 1) ? { margin: 'auto', cursor: 'pointer', color: '#8581C7' } : { margin: 'auto', cursor: 'pointer' }} /></Row>
                    <Row><span style={{ margin: 'auto', paddingTop: '4px', paddingBottom: '4px' }}>0</span></Row>
                    <Row><FaChevronDown onClick={(e) => { voteOnEntry(-1) }} style={(USER_VOTE == -1) ? { margin: 'auto', cursor: 'pointer', color: '#8581C7' } : { margin: 'auto', cursor: 'pointer' }} /></Row>
                </Container>
            </div>
            :
            <div style={{ pointerEvents: 'none', opacity: '0.6' }}>
                <Container>
                    <Row><FaChevronUp onClick={(e) => { voteOnEntry(1) }} style={(USER_VOTE == 1) ? { margin: 'auto', cursor: 'pointer', color: '#8581C7' } : { margin: 'auto', cursor: 'pointer' }} /></Row>
                    <Row><span style={{ margin: 'auto', paddingTop: '4px', paddingBottom: '4px' }}>0</span></Row>
                    <Row><FaChevronDown onClick={(e) => { voteOnEntry(-1) }} style={(USER_VOTE == -1) ? { margin: 'auto', cursor: 'pointer', color: '#8581C7' } : { margin: 'auto', cursor: 'pointer' }} /></Row>
                </Container>
            </div>
    );
}

export default VoteGroup;