import firebase from 'firebase'

var firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
}

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
var db = firebase.firestore();


db.enablePersistence()
    .catch((err) => {
        console.error(err);
        if (err.code == 'failed-precondition') {
            alert("Please close Mapademics on other tabs.")
        } else if (err.code == 'unimplemented') {
        }
    });

export { db, auth };